import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Checkbox, Spin, message } from 'antd';

import { userLogin } from './utils/request';

import styles from './css/Login.module.css';
import logo from './img/logo.png';
import loginAccount from './img/login-account.png';
import loginPsw from './img/login-psw.png';
import loginGuide from './img/login-guide.png'
import Coin from './img/coin.png'

export default function Login () {

        const navigate = useNavigate()

        const [email, setEmail] = useState('')
        const [password, setPassword] = useState('')
        const [checkTerms, setCheckTerms] = useState(false)
        const [loading, setLoading] = useState(false)

        const login = async () => {
            setLoading(true)
            try {
                const result = await userLogin({ email, password })
                setLoading(false)
                console.log('result', result)
                if (result.code == 0 ) {
                    if(result.data && result.data.needInitialize) {
                        navigate('/my-account', {replace: true})
                    } else {
                        navigate('/select-issue', {replace: true})
                    }
                }
            } catch (e) {
                setLoading(false)
            }
          
        }

        const canLogin = email && password && checkTerms

        const showHint = () => {
            let hint = ''
            if (!email) {
                hint = 'Please enter your email'
            } else if (!password) {
                hint = 'Please enter your password'
            } else if (!checkTerms) {
                hint = 'Please indicate your agreement to the Terms and Conditions by checking the box.'
            }
            if (hint) {
                message.warning(hint)
            }

        }

        return (
            <div className={styles.body}>
                <div className={styles.logIn}>
                    <div className={styles.left}>
                        <img src={logo} className={styles.leftLogo} alt="logo" />
                        <div className={styles.leftContent}>
                            <p>Welcome to Argument Arena, an online platform for you to engage in
                            meaningful discussions about important <span className={styles.bold}>global issues.</span> Our platform is
                            designed to help you develop and improve your <span className={styles.bold}>argumentation skills</span> while
                            having fun in <span className={styles.bold}>a gamified learning environment.</span></p>

                            <p>In Argument Arena, you will join a team representing a country and work
                            together with your teammates to research and discuss world issues such
                            as gender equality and cyber security. You will have the opportunity to
                            create and share ideas, practice argumentative writing, and engage in
                            debates.</p>

                            <p>Our gamified approach to learning helps to keep you motivated and
                            engaged. You can earn <img src={Coin} className={styles.coin}/><span className={styles.bold}>‘A-coins’</span> by sharing your ideas and engaging in
                            discussions, and climb up the <span className={styles.bold}>leaderboard</span> to become the top student in
                            your class and even the entire platform.</p>

                            <p>Join Argument Arena! Let's work together to make the world a better place
                            through the power of persuasive communication.</p>

                            <p><a target="_blank" href="https://periwinkle-slash-7d2.notion.site/Argument-Arena-User-Manual-Demo-Video-275f248cf3a7438c86a8b80d59c0a2f8" >User Manual and Demo Video</a></p>
                            
                        </div>
                    </div>
                    <Spin spinning={loading}>
                        <div className={styles.right}>
                            <img src={loginGuide} className={styles.loginGuide} />
                            <div className={styles.rightBox}>
                                <div className={styles.rightTitle}>Log in</div>
                                    <div className={styles.account}>
                                        <img src={loginAccount} className={styles.accountImg} />
                                        <input placeholder='Email' className={styles.input} value={email} onChange={(e) => setEmail(e.target.value)}></input>
                                    </div>
                                    <div className={styles.password}>
                                        <img src={loginPsw} className={styles.pswImg} />
                                        <input type="password" placeholder='Password' className={styles.input} value={password} onChange={(e) => setPassword(e.target.value)}></input>
                                    </div>
                                <div className={styles.rightTerms}>
                                    <Checkbox className={styles.checkbox} onChange={(e) => {setCheckTerms(e.target.checked)}}></Checkbox>
                                    I have read and agree to the<span> </span>
                                    <a href='https://cosmic-porkpie-7f1.notion.site/Argument-Arena-Terms-and-Conditions-1f9e61be95f645d4aa19cf0baad1e092' target="_blank">
                                        <span className={styles.termsLink}>Terms and Conditions</span>
                                    </a>
                                </div>
                                <div className={canLogin ? styles.buttonNormal : styles.buttonDisable} onClick={canLogin ? login : showHint}>
                                    Log in
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
                <a className={styles.link} target="_blank" href='https://cosmic-porkpie-7f1.notion.site/Argument-Arena-Development-Team-e859c6d5174a4c1a958aaada5ced7d3b'>
                    Our development team
                </a>
            </div>
        )
    }

import React from 'react';
import styles from '../css/LevelItem.module.css';
import Finish from '../img/test-finish.png'
import Open from '../img/test-open.png'
import Lock from '../img/test-lock.png'
import Coin from '../img/coin.png'
import { Modal, Button, message } from 'antd';
import QuestionItem from './QuestionItem';
import { getTestAnswers, getTestQuestions, submitTestAnswer } from '../utils/request';
import AnswerItem from './AnswerItem';

export default class LevelItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
      loading: false,
      answers: [],
      questions: [],
      selectAnswer:[],
      submitLoading: false
    }
  }

  showTestAnswer = async () => {
    this.setState({
      loading: true,
      answers: [],
      questions: []
    })
    const data = await getTestAnswers({
      testProfileId: this.props.test.id
    })

    this.setState({
      openModal: true,
      loading: false,
      answers: data.data.answers
    })

  }

  showTestQuestion = async () => {
    this.setState({
      loading: true,
      questions: [],
      answers: []
    })

    const data = await getTestQuestions({
      testProfileId: this.props.test.id
    })

    const answer = data.data.questions.map((item) => null)

    this.setState({
      openModal: true,
      loading: false,
      questions: data.data.questions,
      selectAnswer: answer
    })

    console.log('xx', data)

  }

  submitAnswer = async () => {
    const {selectAnswer, questions} = this.state
    const {reload} = this.props
    const notAnswered = selectAnswer.findIndex(item => item === null)
    if (notAnswered !== -1) {
      message.open({
        type: 'error',
        content: 'Please answer all the questions.',
      });
      return
    }
    
    this.setState({
      submitLoading: true
    })

    const result = await submitTestAnswer({
      testProfileId: this.props.test.id,
      answers: selectAnswer
    })

    this.setState({
      submitLoading: false
    })

    reload()

    this.showTestAnswer()
  }

  onCancel = () => {
    this.setState({
      openModal: false
    })
  }

  setSelectAnswer = (index, value) => {
    const {selectAnswer} = this.state
    selectAnswer[index] = value
    this.setState({
      selectAnswer
    })
  }


  render() {
    const {test, isOpen, indexKey} = this.props
    const {answers, questions, selectAnswer, submitLoading} = this.state
    const hrefList = [
      'https://www.notion.so/Lesson-1-Overview-0dd9413ebe714cafae871ce15b33d25e?pvs=4',
      'https://www.notion.so/Lesson-2-Thesis-Statement-2a1b3ad6f35a4f08bef43a9fe6beccd9?pvs=4',
      'https://www.notion.so/Lesson-3-Introduction-Paragraphs-8f660731200f47e68354490ac234d6a8?pvs=4',
      'https://www.notion.so/Lesson-4-Relevant-Evidence-f926b74fa17541098cd19957fc40b33d?pvs=4',
      'https://www.notion.so/Lesson-5-Body-Paragraphs-32cac4beb0814a5c9fbe7e72406d12c0?pvs=4',
      'https://www.notion.so/Lesson-6-Counter-Arguments-001eb753b57e4fe69df37214d2017c10?pvs=4',
      'https://www.notion.so/Lesson-7-Conclusion-Paragraphs-4cc0940a57484a17a81856a2db32768f?pvs=4'
    ]


    return (
      <div className={styles.item}>
        <a  style={{textDecoration: 'underline' }} target='_blank' href={hrefList[indexKey]}>Lesson {indexKey + 1}</a>
       
        <div className={styles.level}>
          {test.title}
        </div>
        <div className={styles.desc}>{test.name}</div>

        <div className={styles.content}>
          {/* 已测试 */}
          {
            test.userTest && !isOpen &&
            <>
              <img src={Finish} className={styles.finish} />
              <div className={styles.finishButton} onClick={() => this.showTestAnswer()}>
                View
                <div className={styles.arrow}/>
              </div>
              <div className={styles.coinGroup}>
                <div className={styles.coinBox}>
                  <img src={Coin} className={styles.coin}/>x{test.userTest.gainCoins}
                </div>
                </div>
            </>
          }
          {/* 未测试 */}
          {
            isOpen &&
            <>
              <div><img src={Open} className={styles.lock} /></div>
              <div className={styles.openButton} onClick={() => this.showTestQuestion()}>Start</div>
            </>
          }
          {/* 未开放 */}
          {!test.userTest && !isOpen && <img src={Lock} className={styles.lock} />}
        </div>

        <Modal
            open={this.state.openModal}
            centered
            style={{ top: 20, marginBottom: 40 }}
            width={'87%'}
            bodyStyle={{marginRight: '-24px', marginLeft: '-24px'}}
            footer={
              isOpen ? [
              <div className={styles.footer}>
                <Button size="small" loading={submitLoading} className={styles.footerSubmit} onClick={this.submitAnswer} >Submit</Button>
                <div className={styles.footerHint} onClick={this.handleCancel}>You can only answer this test once</div>
              </div>] : null}
              onCancel={this.onCancel}
          >
            <div>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>{test.title}: {test.name}</div>
                <div>
                  {test.userTest && !isOpen && <div className={styles.rightCount}>{answers.filter(item => item.isCorrect).length} / {answers.length}</div>}
                </div>
              </div>
              {
                answers &&
                answers.map((answer, i) => {
                  return(
                    <AnswerItem
                      question={answer.question}
                      answer={answer.answer}
                      questionIndex={i}
                      isCorrect={answer.isCorrect}
                      explanation={answer.explanation}
                      correctAnswer={answer.correctAnswer}
                      key={i}
                    />)
                })
              }
              {
                questions  &&
                questions.map((question, i) => {
                  return(
                    <QuestionItem
                      question={question}
                      questionIndex={i}
                      correct={question.correct}
                      explanation={question.explanation}
                      key={i}
                      selectAnswer={selectAnswer[i]}
                      setSelectAnswer={this.setSelectAnswer}
                    />)
                })
              }
              
            </div>
          </Modal>

      </div>
    )
  }
  
}

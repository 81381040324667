import { useState, useEffect } from 'react';
import Masonry from 'react-masonry-css'
import { message, Modal, Spin } from 'antd'
import { observer } from "mobx-react";
import useUrlState from "@ahooksjs/use-url-state";

import { getCountryIdeas, createIdea } from './utils/request'
import mockData from './mock-data/getCountryIdeas.json'

import styles from './css/CreateIdea.module.css';
import TabBar from './components/TabBar';
import IdeaCountry from './components/IdeaCountry'
import IdeaParticipants from './components/IdeaParticipants'
import IdeaCard from './components/IdeaCard';

import IdeaBooster from './img/idea-booster.png'
import Coin from './img/coin.png'
import userStore from './store/userStore';
import Loading from './components/Loading';
import { countWords } from './utils/utils';


function CreateIdea() {

  const issue = JSON.parse(sessionStorage.getItem('issue'))
  const countries = JSON.parse(sessionStorage.getItem('countries'))
  const selectedCountry = JSON.parse(sessionStorage.getItem('country'))
  const countryIndex = window.location.search.substring(1).split('=')[1]

  const [ideas, setIdeas] = useState()
  const [contentWords, setContentWords] = useState(0)
  const [titleWords, setTitleWords] = useState(0)
  const [currentCountry, selectCountry] = useState(Number(countryIndex))
  const [country, setCountry] = useState(countries[Number(countryIndex)])
  const [pageParams, setPageParams] = useUrlState({countryIndex: countryIndex})
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [modal, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  document.getElementsByTagName('Body')[0].setAttribute('bgcolor', 'F7F7F7')

  async function fetchCountryIdeas(loading) {

    if (loading !== false) {
      setLoading(true)
    }
    const ideas = await getCountryIdeas({countryId: country.countryId})
    // const ideas = mockData.data
    // console.log('ideas', ideas)
    setIdeas(ideas.data)
    setLoading(false)
}

  useEffect(() => {
    fetchCountryIdeas()
  }, [currentCountry])

  useEffect(() => {
    const timer = setInterval(() => {
      fetchCountryIdeas(false)
    }, 10000);
    return () => clearInterval(timer);
  }, [currentCountry]);

  const writeTitle = (e) => {
    const titleWords = countWords(e.target.value)
    setTitleWords(titleWords)
    setTitle(e.target.value)
  }

  const writeContent = (e) => {
    const contentWords = countWords(e.target.value)
    setContentWords(contentWords)
    setContent(e.target.value)
  }

  const showModal = () => {
    setOpen(true)
  }

  const showHint = () => {
    if(titleWords === 0 || titleWords > 6) {
      message.open({
        type: 'warning',
        content: 'Please provide a title that has 6 words at most.',
      })
    } else if (contentWords < 20) {
      message.open({
        type: 'warning',
        content: 'Please input no less than 20 words.',
      })
    }
  }

  async function submitCreateIdea() {
    await createIdea({
      issueId: issue.id,
      countryId: country.countryId,
      title: title,
      content: content
    })
    fetchCountryIdeas()
  }

  const userProfile = userStore.profile
  const canCreateIdea = (selectedCountry.countryId === countries[currentCountry].countryId) && (userProfile && !userProfile.isAdmin)
  console.log(titleWords)
  const canSubmitIdea = titleWords !== 0 && titleWords <= 6 && contentWords >= 20
  const boosterLink = issue.profile.type === 'Issue A' ? 'https://www.notion.so/Idea-Booster-Issue-A-Gender-Equality-e05bfa25e47f451583265254f18fa6fb?pvs=4' :
    issue.profile.type === 'Issue B' ? 'https://www.notion.so/Idea-Booster-Issue-B-Life-Below-Water-4f6dbd24d5d145eeacfb41d1c2e8d805?pvs=4' :
    'https://www.notion.so/Idea-Booster-Issue-C-Drug-Trafficking-97d63489c281409489e1e2204c7db25b?pvs=4'


  if(!userProfile) { return <Loading /> }  
  return (
    <div>
      <TabBar currentTab='create-idea'/>
        <div  className={styles.page}>
          <div className={styles.head}>
            {countries && countries.map((country, index)=> {
              return (
                <IdeaCountry
                  country={country}
                  isSelected={currentCountry === index}
                  onClick={() => {
                    selectCountry(index)
                    setCountry(country)
                    setPageParams({countryIndex: index})
                  }}
                  key={index}
                />)
            })}
          </div>

          <div className={styles.body}>

            <div className={styles.left}>
              <div>
                <pre style={{whiteSpace: 'pre-wrap', fontSize: '13px', lineHeight: '20px', color: '#898989', fontFamily: 'PingFangSC-Regular'}} dangerouslySetInnerHTML={{__html: issue.profile.ideaHint}}>
                </pre>
              </div>
            </div>


              <div className={styles.right}>
                {canCreateIdea && ideas && ideas.length === 0 &&
                <div className={styles.hint}>
                  Your team hasn't created any ideas yet. Don't be afraid to share - every idea counts!
                </div>}
                {!canCreateIdea && ideas && ideas.length === 0 &&
                <div className={styles.hint}>
                  Representatives from this country have not created any ideas.
                </div>}
                <Masonry
                  breakpointCols={3}
                  className={styles.masonryGrid}
                  columnClassName={styles.masonryColumn}
                >

                  {canCreateIdea && 
                    <div className={styles.writeIdea}>
                      <div className={styles.UserInfo}>
                        <img src={userProfile.avatar} className={styles.userAvatar} />
                        <div className={styles.userName}>{userProfile.username}</div>
                      </div>

                      <div>
                        <input placeholder='Idea title (no more than 6 words)' className={styles.title} onChange={writeTitle} value={title}/>
                      </div>
                      <div className={styles.inputDivider} />
                      <textarea placeholder='Type here (no less than 20 words)' className={styles.input} onChange={writeContent} value={content}/>
                      
                      <div className={styles.Ideawrapper}>

                        <div className={styles.wordCounter}>
                          {contentWords} words
                        </div>

                        <div className={canSubmitIdea ? styles.createGroup : styles.disabled} onClick={canSubmitIdea ? showModal : showHint}>
                          <div className={styles.create}>Create</div>
                          {/* <div className={styles.buttonDivider} /> */}
                          <div className={styles.coinGroup}>
                            <img src={Coin} className={styles.coin}/>
                            <span className={styles.coinNums}>+10</span>
                          </div>
                        </div>
                      </div>
                    </div>}

                  {loading ? <Spin spinning={true}></Spin> : (ideas && ideas.map((idea, index) => {
                    return <IdeaCard idea={idea} key={index} refreshData={fetchCountryIdeas}/>
                  }))}

                </Masonry>
              </div>


          </div>
          <div className={styles.footer}>
            <div className={styles.ideaBooster} onClick={() => window.open(boosterLink)}>
              <img src={IdeaBooster} className={styles.boosterImg} />
              Idea Booster
            </div>
            <div className={styles.userGroup}>
              {
                country.members.length > 0 &&
                country.members.map((member, index) => {
                  return(
                    <IdeaParticipants member={member} key={index} />
                  )
                })
              }
            </div>
          </div>
      </div>

      <Modal
          open={modal}
          centered
          footer={null}
          closable={false}
        >
          <div className={styles.modal}>
            <div className={styles.modalTitle}>Attention</div>
            <div className={styles.modalContent}>Are you sure you want to post your idea? You won't be able to revise or delete it after posting.</div>
            <div className={styles.modalBtnGroup}>
              <div
                className={styles.modalBtnOk}
                onClick={() => {
                  setOpen(false)
                  setTitle('')
                  setTitleWords(0)
                  setContent('')
                  setContentWords(0)
                  submitCreateIdea()
                }}
              >Confirm</div>
              <div className={styles.modalBtnCancel} onClick={() => setOpen(false) }>Cancel</div>
            </div>
          </div>
        </Modal>
    </div> 
  )
}


export default observer(CreateIdea)
import React from 'react';
import styles from '../css/Issue.module.css'
import IssueAImg from '../img/issue-a.png'
import IssueBImg from '../img/issue-b.png'
import IssueCImg from '../img/issue-c.png'

class  Issue extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {

        const { issue, isSelected } = this.props

        const type = issue.profile.type
        const imgSrc = type === 'Issue A' ? IssueAImg : type === 'Issue B' ? IssueBImg : IssueCImg
        const title = issue.profile.title

        return (
            <div className={styles.body} onClick={this.props.changeIssue}>
                <div className={isSelected ? styles.selected : styles.normal}>
                    <div className={type === 'Issue A' ? styles.issueA : type === 'Issue B' ? styles.issueB : styles.issueC}>
                        <img src={imgSrc} className={type === 'Issue A' ? styles.imgA : type === 'Issue B' ? styles.imgB : styles.imgC} />
                        <div className={styles.issue}>{type}</div>
                        <div className={styles.title}>{title}</div>
                    </div>
                </div>
                <div className={styles.status}>
                    {
                        issue.status === 0 ? '(Not Started)' : issue.status === 1 ? '(In Progress)' : '(Completed)'
                    }
                </div>
            </div>
        )
    }
  }
  
  export default Issue;
import React from 'react'
import dayjs from 'dayjs'
import { message } from 'antd'

import { createLike, createComment } from '../utils/request'
import styles from '../css/CreateComment.module.css'
import Like from './Like'
import Comment from './Comment'
import userStore from '../store/userStore'
import { observer } from 'mobx-react';
import Loading from './Loading'
import Linkify from "linkify-react";
import { countWords } from '../utils/utils'
import Coin from '../img/coin.png'
import TextArea from 'antd/es/input/TextArea'


class CreateComment extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      countWords: 0,
      comment: '',
      submitting: false,
    }
  }

  writeComment = (e) => {
    const count = countWords(e.target.value)
    console.log(count)
    this.setState({
      countWords: count,
      comment: e.target.value
    })
  }

  likeIdea = async() => {
    await createLike({ideaId: this.props.idea.id})
    this.setState({
      likeIdea: true
    })
  }

  showHint = () => {
    if(this.state.countWords < 10 ){
      message.open({
        type: 'warning',
        content: 'Please input no less than 10 words.',
      })
    }
  }

  submitComment = async() => {
    const { submitting } = this.state
    if (submitting) return
    this.setState({
      countWords: 0,
      comment: '',
      submitting: true,
    })
    await createComment({
      ideaId: this.props.idea.id,
      content: this.state.comment
    })
    this.setState({
      countWords: 0,
      comment: '',
      submitting: false,
    })
    this.props.refreshData(false)
  }

  handleSubmit = () => {
    this.submitComment()
  }


  render() {
    const { idea } = this.props
    const userProfile = userStore.profile
    const canComment = this.state.countWords >= 10

    if(!userProfile) { return <Loading /> }  

    return (
      <div className={styles.body}>

        <div className={styles.userInput}>
          <div className={styles.commenterInfo}>
            <img src={userProfile.avatar} className={styles.commenterAvatar} />
          </div>

          <div className={styles.writeComment}>
            <TextArea
              autoSize={{ minRows: 1, maxRows: 10 }}
              placeholder='Type here (no less than 10 words)'
              className={styles.textarea}
              onChange={this.writeComment}
              value={this.state.comment}
              bordered={false}
            />
          </div>
        </div>

        <div className={styles.rightDivider} />

        <div className={styles.btnWrapper}>
          <div className={styles.wordCounter}>{this.state.countWords} words</div>
          <div className={canComment ? styles.addComment : styles.disabledComment} onClick={canComment ? this.handleSubmit : this.showHint}>
            <div className={styles.desc} >Add comment</div>
            {/* <div className={styles.buttonDivider} />  */}
            <div className={styles.coinGroup}>
              <img src={Coin} className={styles.coin}/>
              <span className={styles.coinNums}>+5</span>
            </div>
          </div>
        </div>

           
      </div>
    )
  }
  
}

export default observer(CreateComment)
import { createBrowserRouter } from 'react-router-dom';
import Root from './Root'
import Login from './Login';
import MyAccount from './MyAccount';
import SelectIssue from './SelectIssue'
import CreateIdea from './CreateIdea';
import WriteProposal from './WriteProposal'
import EvaluationCommittee from './EvaluationCommittee'
import Score from './Score'
import KnowledgeBattle from './KnowledgeBattle';
import ViewData from './ViewData';
import ViewUserDetail from './ViewUserDetail';
import ManageUser from './ManageUser';
import CRM from './CRM';
import ViewKBData from './ViewKBData';

const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <h1>404 Not Found</h1>,
      children: [
        {
          path: "/",
          element: <Login />,
        },
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "my-account",
          element: <MyAccount />,
        },
        {
          path: "select-issue",
          element: <SelectIssue />,
        },
        {
          path: "create-idea",
          element: <CreateIdea />,
        },
        {
          path: "write-proposal",
          element: <WriteProposal />,
        },
        {
          path: "evaluation-committee",
          element: <EvaluationCommittee />,
        },
        {
          path: "score",
          element: <Score />,
        },
        {
          path: "knowledge-battle",
          element: <KnowledgeBattle />,
        },
        {
          path: "admin-view-data",
          element: <ViewData />,
        },
        {
          path: "/view-user-detail",
          element: <ViewUserDetail />,
        },
        {
          path: "admin-manage-user",
          element: <ManageUser />,
        },
        {
          path: "admin-crm",
          element: <CRM />,
        },
      ],
    },
  ])

  export default router
import React from 'react';
import { Rate } from 'antd';
import dayjs from 'dayjs'

import styles from '../css/CommentDetail.module.css';
export default class CommentDetail extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {

    const { evaluation } = this.props
    const createDate = dayjs(evaluation.createAt).format('YYYY/MM/DD')

    return (
      <div className={evaluation.byAdmin ? styles.adminCard : styles.normalCard}>
        <div className={styles.scorer}>
          <img src={evaluation.creator.avatar} className={styles.avatar} />
          <div className={styles.name}>{evaluation.creator.username}</div>
          <div className={styles.date}>{createDate}</div>
        </div>
        <div className={styles.evaluateGroup}>
          <div className={styles.evaluateItem}>
            <div className={styles.evaluateDesc}>Content</div>
            <Rate
              allowHalf
              disabled
              value={evaluation.contentScore}
              style={{color:'#F09B31'}}
            />
          </div>
          <div className={styles.evaluateItem}>
            <div className={styles.evaluateDesc}>Organization</div>
            <Rate
              allowHalf
              disabled
              value={evaluation.organizationScore}
              style={{color:'#F09B31'}}
            />
          </div>
          <div className={styles.evaluateItem}>
            <div className={styles.evaluateDesc}>Language</div>
            <Rate
              allowHalf
              disabled
              value={evaluation.languageScore}
              style={{color:'#F09B31'}}
            />
          </div>
        </div>
      
        <div className={styles.divider} />
        <div className={styles.content}>{evaluation.content}</div>
      </div>
    )
  }

}

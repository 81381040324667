import React from 'react'

import styles from '../css/Like.module.css'
import LikeGray from '../img/like-gray.png'
import LikeOrange from '../img/like-orange.png'

export default class Like extends React.Component {

  constructor(props) {
    super(props)
  }


  render() {
    const { likes, likeIdea } = this.props

    return (
      <div className={styles.group}>
        <img src={likeIdea ? LikeOrange : LikeGray} className={styles.icon} onClick={this.props.onClick}/>
        {likes}
      </div>
    )
  }
  
}

import React from 'react'
import dayjs from 'dayjs'
import  { Modal } from 'antd'

import { createLike, cancelLike } from '../utils/request'
import Linkify from "linkify-react";
import styles from '../css/IdeaCard.module.css'
import Like from './Like'
import Comment from './Comment'
import CreateComment from './CreateComment'

export default class IdeaCard extends React.Component {

  constructor(props) {
    super(props)
  }

  // showModal = () => {
  //   this.setState({openModal: true})
  // }
  
  // hideModal = () => {
  //   this.setState({openModal: false})
  // }

  // closeModal = () => {
  //   this.hideModal()
  //   this.props.refreshData()
  // }

  handleLike = async () => {
    const { idea, refreshData } = this.props
    if (idea.likeByMe) {
      await cancelLike({ideaId: this.props.idea.id})
      refreshData(false)
    } else {
      await createLike({ideaId: this.props.idea.id})
      refreshData(false)
    }
  }

  render() {

    const { idea, refreshData } = this.props
    const ideaCreateDate = dayjs(idea.createAt).format('YYYY/MM/DD HH:mm:ss')

    return (
      <div className={styles.body}>
        <div className={styles.userInfo}>
          <img src={idea.creator.avatar} className={styles.userAvatar} />
          <div className={styles.userName}>{idea.creator.username}</div>
          <div className={styles.createDate}>{ideaCreateDate}</div>
        </div>

        <div className={styles.ideaInfo}>
          <div className={styles.ideaTitle}>{idea.title}</div>
          <Linkify options={{target: '_blank'}}><div className={styles.ideaContent}>{idea.content}</div> </Linkify>
        </div>

        <div className={styles.divider} />

        <div className={styles.interactionGroup}>
          <div className={styles.likeAndComment}>
            <Like likes={idea.likes.length} onClick={this.handleLike} likeIdea={idea.likeByMe} />
            <Comment comments={idea.comments.length}/>
          </div>
          
          
        </div>

        {
          idea.comments.length > 0 && idea.comments.map((comment, index) => {
            const commentCreateDate = dayjs(comment.createAt).format('YYYY/MM/DD HH:mm:ss')
            return (
              <div className={styles.comments} key={index}>
                <div className={styles.commentUserInfo}>
                  <img src={comment.user.avatar} className={styles.commentUserAvatar} />
                  <div className={styles.commentUserName}>{comment.user.username}</div>
                  <div className={styles.commentCreateDate}>{commentCreateDate}</div>
                </div>
                <Linkify options={{target: '_blank'}}>
                  <div className={styles.commentContent}>
                    {comment.content}
                  </div>
                </Linkify>
              </div>
            )
          })
        }

        <CreateComment idea={idea} refreshData={refreshData}/>

        {/* <Modal
          open={this.state.openModal}
          centered
          width={'75vw'}
          footer={null}
          onCancel={e => {
            e.stopPropagation()
            this.hideModal()
         }}
        >
          <AddComment
            idea={idea}
            likeIdea={this.state.likeIdea}
            closeModal={this.closeModal}
          />
        </Modal> */}
        
      </div>
    )
  }
  
}

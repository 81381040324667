import React from 'react';
import styles from '../css/ShowPoints.module.css';
import Coin from '../img/coin.png'
import Close from '../img/close.png'
import Leaderboard from '../img/leader-board.png'
import { Spin } from 'antd';
import { getLeaderBoard } from '../utils/request';

export default class ShowPoints extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      currentTab: 'classBoard',
      data: undefined,
      showData: [],
    }
    this.fetchData()
  }

  fetchData = async () => {
    const data = await getLeaderBoard()
    this.setState({
      data: data.data,
      showData: data.data[this.state.currentTab]
    })
  }

  changeTabs = (currentTab) => {
    if (this.state.data) {
      const showData = this.state.data[currentTab]
      this.setState({
        showData,
        currentTab: currentTab
      })
    }
  }

  render() {

    const content = [
      {
        describe: '1. Create a new idea',
        coin: 10,
      },{
        describe: '2. Comment on an existing idea',
        coin: 5,
      },{
        describe: '3. Submit a proposal',
        coin: 100,
      },{
        describe: '4. Evaluate a proposal',
        details:[{
          describe: '- From the same country',
          coin: 20
        },{
          describe: '- From a different country',
          coin: 15
        }],
      },{
        describe: '5. Knowledge Battle',
        details:[{
          describe: '- Answer a question correctly',
          coin: 10
        }]
      }
    ]


    const Rankings = () => {
      const { loading, showData } = this.state 
      return (
        <Spin spinning={loading} style={{marginBottom: 20}}>
          {
            showData.map((item, i) => {
              return  <div className={styles.rankItem} key={i}>
                
                {
                  i < 3 ? <img src={require(`../img/Level${i+1}.png`)} className={styles.rankImg}/> :  <div className={styles.rankIndex}>{i+1}</div> 
                }

                <img className={styles.avatar} src={item.avatar} />
                <div className={styles.rankName}>{item.username}</div>
                <div className={styles.rankCoin}> <img src={Coin} className={styles.leftCoin}/> {item.coins}</div>
              </div>
            })
          }
        </Spin>
      )
    }

    return (
      <div className={styles.body}>
        <img src={Close} className={styles.close} onClick={this.props.hideModal()}/>
        <div className={styles.left}>
          <div className={styles.leftTitle}>How to gain <img src={Coin} className={styles.titleCoin}/>  A-coins?</div>
            <div className={styles.leftContent}>
              {content.map((content, index) => {
                const hasDetail = content.details && content.details.length > 0
                return(
                  <div  className={hasDetail ? styles.hasDetail : styles.noDetail} key={index}>
                      <div>{content.describe}</div>
                      {
                        hasDetail ?
                        content.details.map((detail, index) =>
                        <div className={styles.detail} key={index}>
                          {detail.describe}
                          <img src={Coin} className={styles.leftCoin}/>
                          <div className={styles.leftCoinNums}>+{detail.coin}</div>
                        </div>
                        ) :
                        <div className={styles.detail} key={index}>
                          <img src={Coin} className={styles.leftCoin}/>
                          <div className={styles.leftCoinNums}>+{content.coin}</div>
                        </div>
                      }
                  </div>
                  )}
              )}
            </div>
        </div>
        <div className={styles.right}>
          <img src={Leaderboard} className={styles.leaderboard}/>
          <div className={styles.tabs}>
            <div className={this.state.currentTab == 'classBoard' ? styles.currentTab : styles.anotherTab} onClick={() => this.changeTabs('classBoard')}>
              Your class
            </div>
            <div className={styles.allClasses}>
              <div className={this.state.currentTab == 'completeBoard' ? styles.currentTab : styles.anotherTab} onClick={() => this.changeTabs('completeBoard')}>
                All classes
              </div>
            </div>
          </div>
          <div className={styles.rightContent}>
            <Rankings />
          </div>
        </div>
      </div>
    )
  }
  
}

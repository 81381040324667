import React, { useState, useEffect } from 'react';
import { Table, Pagination, Popover, Button } from 'antd';
import { getUserDataReq, getClass } from './utils/request';

import TabBar from './components/TabBar';
import styles from '../src/css/ViewData.module.scss'
import { useNavigate } from 'react-router';

function ViewUserData(props) {

  const [classInfo, setClassInfo] = useState()
  const [userPage, setUserPage] = useState(1)
  const [userData, setUserData] = useState()
  const [loading, setLoading] = useState(false)
  const [classFilter, setClassFilter] = useState()
  const navigate = useNavigate()

  const gotoDetail = (id) => {
    // navigate('/view-user-detail', { state: { id } })
    window.open(`/view-user-detail?id=${id}`)
  }

  useEffect(() => {
    getClassInfo()
  }, [])

  const getClassInfo = async () => {
    const classInfo = await getClass()
    setClassInfo(classInfo.data)
  }


  useEffect(() => {
    getUser()
  }, [userPage, classFilter])

  const getUser = async () => {
    setLoading(true)
    const userData = await getUserDataReq({
      page: userPage,
      pageLimit: 10,
      ...classFilter
    })
    if (userData) {
      setUserData(userData.data)
    }
    setLoading(false)
  }

  const userColumns = [
    {
      title: 'User information',
      children: [
        {
          title: 'Name',
          dataIndex: 'username',
          key: 'username',
          onCell: (_, index) => {
            if (index % 3 === 0) {
              return { rowSpan: 3 }
            } else {
              return { rowSpan: 0 }
            }
          },
        },
        {
          title: 'Gender',
          dataIndex: 'showGender',
          key: 'showGender',
          onCell: (_, index) => {
            if (index % 3 === 0) {
              return { rowSpan: 3 }
            } else {
              return { rowSpan: 0 }
            }
          },
        },
        {
          title: 'Class',
          dataIndex: 'showClass',
          key: 'classId',
          filters: classInfo && classInfo.map(item => ({ text: item.name, value: item.id })),
          filterMultiple: false,
          onCell: (_, index) => {
            if (index % 3 === 0) {
              return { rowSpan: 3 }
            } else {
              return { rowSpan: 0 }
            }
          },
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          onCell: (_, index) => {
            if (index % 3 === 0) {
              return { rowSpan: 3 }
            } else {
              return { rowSpan: 0 }
            }
          },
        },
      ],
    },
    {
      title: 'A-coins',
      children: [
        {
          title: 'A-coins gained',
          dataIndex: ['issueCoin'],
          key: 'userCoinsGained',
          onCell: (_, index) => {
            if (index % 3 === 0) {
              return { rowSpan: 3 }
            } else {
              return { rowSpan: 0 }
            }
          },
        }
      ]
    },
    {
      title: 'Issue & country',
      children: [
        {
          title: 'Issue',
          dataIndex: ['issue', 'profile', 'type'],
          key: 'showIssue',
        },
        {
          title: 'Country',
          dataIndex: ['issue', 'joinCountry'],
          key: 'country',
          render: (text) => {
            console.log(text, 'text')
            return text ? text.countryProfile.name : 'N/A'
          }
        }
      ]
    },
    {
      title: 'Idea Creation',
      children: [
        {
          title: 'Ideas created',
          dataIndex: ['issue', 'ideaCount'],
          key: 'ideasCreated',
        },
        {
          title: 'Comments given',
          dataIndex: ['issue', 'commentCount'],
          key: 'commentsGiven',
        },
        {
          title: 'Likes given',
          dataIndex: ['issue', 'likeCount'],
          key: 'likesGiven',
        }
      ]
    },
    {
      title: 'Evaluation committee',
      children: [
        {
          title: 'Proposals evaluated',
          dataIndex: ['issue', 'evaluationCount'],
          key: 'proposalsEvaluated',
        }
      ]
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      width: 200,
      fixed: 'right',
      render: (_, record) => {
        return  <Button size='small' type='primary' onClick={() => gotoDetail(record.id)}>Detail</Button>
      },
      onCell: (_, index) => {
        if (index % 3 === 0) {
          return { rowSpan: 3 }
        } else {
          return { rowSpan: 0 }
        }
      },
    },
    
  ]

  const data = []

  userData && userData.data && userData.data.forEach((user, index) => {

    const dataItem = {
      id: user.id,
      username: user.username,
      email: user.email,
      showGender : user.gender === 'M' ? 'Male' : 'Female',
      showClass : user.class && user.class.name,
      showIssue : user.issue && user.issue.type,
      issueCoin: user.issueCoin,

    }

    user.issues.length > 0 && user.issues.forEach((issue) => {
      data.push({
        ...dataItem,
        issue,
      })
    })

  })

  return (
    <div>
      <div className={styles.page}>
        <div className={styles.tableOne}>
          <div>Student Contribution Data</div>
          <Table
            className={styles.table}
            columns={userColumns}
            dataSource={data}
            sticky
            bordered
            pagination={false}
            loading={loading}
            onChange={ (pagination, filters) => setClassFilter(filters)}
          />
          <div className={styles.pagination}>
            <Pagination
              current={userPage}
              showTotal={(total) => `Total ${total} items`}
              total={userData && userData.count}
              onChange={(page) => {
                console.log(page);
                setUserPage(page)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )

  
}


export default ViewUserData;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import  { Popover, Modal } from 'antd';
import { observer } from "mobx-react";

import userStore from '../store/userStore';
import selectIssueStore from '../store/selectIssueStore';
import styles from '../css/TabBar.module.css';
import Icon from '../img/favicon.png'
import Writting from '../img/tabbar-writing.png'
import Battle from '../img/tabbar-battle.png'
import Chat from '../img/tabbar-chat.png'
import Coin from '../img/coin.png'
import ShowPoints from './ShowPoints';
import Loading from './Loading';
import Logo from '../img/logo.png'
import { AnimatedCounter } from  'react-animated-counter';

function TabBar(props) {
    
    useEffect(() => {
      userStore.refresh()
    }, [])

    const [openModal, setOpenModal] = useState(false)

    const navigate = useNavigate()
  
  
    const showModal = () => {
      setOpenModal(true)
    }
    
    const hideModal = () => {
      setOpenModal(false)
    }

    const handleKnowledgeBattle = () => {
      navigate('/knowledge-battle')
    }

    const logout = () => {
      localStorage.removeItem('token')
      navigate('/login')
    }

    const handleFeedback = () => {
      window.open('https://cosmic-porkpie-7f1.notion.site/Argument-Arena-User-Feedback-35eba80946a14a80982917110b4bad42 ')
    }

    const viewData = () => {
      navigate('/admin-view-data')
    }

    const viewKBData = () => {
      navigate('/admin-view-kb-data')
    }

    const manageUser = () => {
      navigate('/admin-manage-user')
    }

    const goCRM = () => {
      navigate('/admin-crm')
    }


    const content = [
      {
        describe: '1. Create a new idea',
        coin: 10,
      },{
        describe: '2. Comment on an existing idea',
        coin: 5,
      },{
        describe: '3. Submit a proposal',
        coin: 100,
      },{
        describe: '4. Evaluate a proposal',
        details:[{
          describe: '- From the same country',
          coin: 20
        },{
          describe: '- From a different country',
          coin: 15
        }],
      },{
        describe: '5. Knowledge Battle',
        details:[{
          describe: '- Answer a question correctly',
          coin: 10
        }]
      }
    ]

    const { currentTab, hideMenu } = props
    const selectIssue = selectIssueStore.selectIssue
    const userProfile = userStore.profile


    if (!userProfile) return <Loading />

    return (
      <div className={styles.body}>
        
        <div className={styles.left}>
          {hideMenu ? <img src={Logo} className={styles.leftLogo} alt="logo" /> : <img src={Icon} className={styles.img} /> }
          {hideMenu ||
            <>
              <div className={styles.firstTab} onClick={() => navigate('/select-issue')}>Issue {selectIssue === 0 ? 'A' : selectIssue === 1 ? 'B' : 'C'}</div>
              <div className={styles.menuDivider}></div>
              <div className={currentTab === 'create-idea' ? styles.currentTab : styles.normalTab} onClick={() => navigate('/create-idea?countryIndex=0')}>Idea Creation</div>
              <div className={currentTab === 'write-proposal' ? styles.currentTab : styles.normalTab} onClick={() => navigate('/write-proposal')}>Proposal Writing</div>
              <div className={currentTab === 'evaluation-committee' ? styles.currentTab : styles.normalTab} onClick={() => navigate('/evaluation-committee')}>Evaluation Committee</div>
            </>
          }
        </div>

        <div className={styles.right}>
          {hideMenu ? null: 
            <div className={styles.buttonGroup}>
              <div className={styles.button} onClick={() => window.open('https://www.notion.so/Becoming-A-Successful-Argumentative-Writer-f2bd2613ae334d4e8183afe71067f285?pvs=4')}>
                <img src={Writting} className={styles.writing}/>
                Writing Guides
              </div>
              <div className={styles.button} onClick={handleKnowledgeBattle}>
                <img src={Battle} className={styles.battle}/>
                Knowledge Battle
              </div>
              <div className={styles.button} onClick={() => window.open('https://www.notion.so/Practice-with-Chatbot-7f82d3bd3878405584306c0b0a4937bf?pvs=4')}>
                <img src={Chat} className={styles.chat}/>
                Chatbot Practice
              </div>
            </div>
          }
          <div className={styles.coin} onClick={showModal}>
            <img src={Coin} className={styles.coinIcon}/>

            <AnimatedCounter
              value={userStore.profile.coins}
              includeDecimals={false}
              fontSize='12px'
              incrementColor="#ff6200"
            />
          </div>
          <Popover
              content={
                <div>
                  <Link to='/my-account'><div className={styles.myAccount}>My account</div></Link>
                  <div className={styles.divider} />
                  <div className={styles.logOut} onClick={handleFeedback}>User feedback</div>
                  {
                    userProfile.isAdmin &&
                    <div>
                      <div className={styles.divider} />
                      <div className={styles.logOut} onClick={viewData}>View data</div>
                      <div className={styles.divider} />
                      <div className={styles.logOut} onClick={manageUser}>Manage users</div>
                      <div className={styles.divider} />
                      <div className={styles.logOut} onClick={goCRM}>CRM</div>
                    </div>
                  }
                  <div className={styles.divider} />
                  <div className={styles.logOut} onClick={logout}>Log out</div>
                </div>
              }
              arrow={false}
              trigger="click"
              placement="bottomRight"
              overlayClassName={styles.pop}
            >
            <div className={styles.userInfo}>
              <img src={userStore.profile.avatar} className={styles.userAvatar} />
                <div className={styles.userName}>{userStore.profile.username}</div>
            </div>
          </Popover>
        </div>
  
        <Modal
          open={openModal}
          centered
          maskClosable={true}
          width={'79vw'}
          destroyOnClose={true}
          style={{pointerEvents: 'auto', top: 20, marginBottom: 40}}
          modalRender={() => {
            return(<ShowPoints hideModal={() => hideModal}/>)
          }}
        >
        </Modal>

      </div>
    )

  
}


export default observer(TabBar);
import React, { useState, useEffect } from 'react';
import { Table, Pagination, Popover } from 'antd';
import { getUserKnowledgeData, getClass } from './utils/request';

import TabBar from './components/TabBar';
import styles from '../src/css/ViewData.module.scss'

function ViewKBData(props) {

  const [classInfo, setClassInfo] = useState()

  const [userPage, setUserPage] = useState(1)
  const [userPageLimit, setUserPageLimit] = useState(10)
  const [knowledgePage, setKnowledgePage] = useState(1)
  const [knowledgePageLimit, setKnowledgePageLimit] = useState(10)
  const [knowledgeData, setKnowledgeData] = useState()
  const [userData, setUserData] = useState()
  const [classFilter, setClassFilter] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getClassInfo()
  }, [])

  const getClassInfo = async () => {
    const classInfo = await getClass()
    setClassInfo(classInfo.data)
  }


  useEffect(() => {
    getKnowledgeData()
  }, [knowledgePage, classFilter, knowledgePageLimit])


  const getKnowledgeData = async () => {
    setLoading(true)
    const result = await getUserKnowledgeData({
      page: knowledgePage,
      pageLimit: knowledgePageLimit,
      ...classFilter
    })
    if (result) {
      setKnowledgeData(result.data)
    }
    setLoading(false)
  }

  const renderer = (text, record) => {
    if (!text) return 'N/A'

    const testCorrectNum = text.answers.filter(answer => answer.isCorrect === true).length

    const convertIndexToChar = (index) => {
      return (String.fromCharCode(65 + index)).toLowerCase()
    }

    console.log(record)
    const content = (
      <div>
        {
          text.answers.map((item, index) => {
            return <p>Q{index + 1}: <span style={{color: item.isCorrect ? '#1CAA35' : '#F64545' }}>{convertIndexToChar(item.answer)}</span></p>
          })
        }
      </div>
    );
    return <Popover content={content}>{ `${testCorrectNum} / ${text.answers.length}`}</Popover>
  }

  const knowledgeColumns = [
    {
      title: 'Name',
      dataIndex: 'username',
      key: 'username',
      width: 200,
    },
    {
      title: 'Class',
      dataIndex: ['class', 'name'],
      key: 'classId',
      filters: classInfo && classInfo.map(item => ({ text: item.name, value: item.id })),
      filterMultiple: false,
      
    },
    {
      title: 'Test 1 correct answers',
      dataIndex: 'test1',
      key: 'test1',
      render: renderer
    },
    {
      title: 'Test 2 correct answers',
      dataIndex: 'test2',
      key: 'test2',
      render: renderer
    },
    {
      title: 'Test 3 correct answers',
      dataIndex: 'test3',
      key: 'test3',
      render: renderer
    },
    {
      title: 'Test 4 correct answers',
      dataIndex: 'test4',
      key: 'test4',
      render: renderer
    },
    {
      title: 'Test 5 correct answers',
      dataIndex: 'test5',
      key: 'test5',
      render: renderer
    },
    {
      title: 'Test 6 correct answers',
      dataIndex: 'test6',
      key: 'test6',
      render: renderer
    },
    {
      title: 'Test 7 correct answers',
      dataIndex: 'test7',
      key: 'test7',
      render: renderer
    },
    {
      title: 'A-coins gained',
      dataIndex: 'gainCoin',
      key: 'gainCoin',
    },
  ]

  knowledgeData && knowledgeData.data && knowledgeData.data.forEach((user) => {
    user.gainCoin = 0
    if (user.tests.length > 0) {
      user.tests.forEach((test, index) => {
        user[`test${index + 1}`] = test
      })
      user.gainCoin = user.tests.reduce((total, test) => {
        return total + test.gainCoins
      }, 0)
    }
  })

  return (
    <div>
      <div className={styles.page}>
        <div className={styles.tableTwo}>
          <div>Knowledge Battle Data</div>
          <Table
            className={styles.table}
            columns={knowledgeColumns}
            dataSource={knowledgeData && knowledgeData.data}
            sticky
            bordered
            pagination={false}
            loading={loading}
            onChange={ (pagination, filters) => setClassFilter(filters)}

          />
          <div className={styles.pagination}>
            <Pagination
              current={knowledgePage}
              showTotal={(total) => `Total ${total} items`}
              total={knowledgeData && knowledgeData.count}
              onChange={(page) => setKnowledgePage(page)}
              pageSizeOptions={[10, 20, 50]}
              showSizeChanger
              onShowSizeChange={(current, pageSize) => {
                setKnowledgePageLimit(pageSize)
              }}
            />
        </div>
        </div>
      </div>
    </div>
  )

  
}


export default ViewKBData;
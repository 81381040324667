
import { Spin } from 'antd';
import React, { Component } from 'react';
import LevelItem from './components/LevelItem';
import Loading from './components/Loading';
import TabBar from './components/TabBar';
import styles from './css/KnowledgeBattle.module.css';
import Coin from './img/coin.png'
import { getTestProfiles } from './utils/request';


export default class KnowledgeBattle extends Component {

  constructor(props) {
      super(props)
      this.state = {
        levelData: [],
        loading: false 
      }
  }

  componentDidMount () {
    // 请求数据 setData
    this.fetchData()
  }

  fetchData = async() => {
    this.setState({
      loading: true
    })
    const result = await getTestProfiles()
    this.setState({
      levelData: result.data,
      loading: false
    })
  }
    

  render () {

    const { levelData, loading } = this.state
    const openItem = levelData.find(item => !item.userTest)

      const CircleGroup = () => {
        return (<div className={styles.circleGroup}>
          <div className={styles.circle} />
          <div className={styles.circle} />
          <div className={styles.circle} />
        </div>)
      }

      return (
        <div>
          <TabBar currentTab='knowledge-battle'/>
        
          <div className={styles.page}>

            <div className={styles.title}>Knowledge Battle</div>
            <div className={styles.description}>
              <p>Knowledge Battle is a competition where participants can showcase their expertise in argumentative writing. Seven tests, each with five questions. Correctly answering one question earns you  <img src={Coin} className={styles.coin}/> 10 A-coins. You can earn up to  <img src={Coin} className={styles.coin}/> 50 A-coins per test and  <img src={Coin} className={styles.coin}/> 350 A-coins in total.</p>
              <p>Learn about argumentative writing by clicking <a style={{color: '#0091FF'}} href="https://www.notion.so/Becoming-A-Successful-Argumentative-Writer-f2bd2613ae334d4e8183afe71067f285?pvs=4" target="_blank">here</a> before attempting the seven tests. It'll help you do better and earn more!</p>
            </div>
            <div className={styles.divider} />
            <Spin spinning={loading}>
              <div className={styles.levelGroup}>
                {
                  levelData.length > 0 && levelData.map((e, i) => {
                    return <>
                      <LevelItem reload={this.fetchData} indexKey={i} test={e} key={i} isOpen={openItem && openItem.id === e.id}/>
                      {
                        levelData.length !== i+1 && <CircleGroup key={i}/>
                      }
                    </>
                  })
                }
              </div>
            </Spin>

            
          </div>
        </div>
      )
    }
}


import { makeAutoObservable } from "mobx";
import { getEvaluationList } from "../utils/request";

// Todo 總數據源
class EvaluationsStore {
  evaluations = null
  selectedProposal = null

  constructor() {
    makeAutoObservable(this);
  }

  async refreshEvaluationList(data) {
    const evaluations = await getEvaluationList(data)
    this.evaluations = evaluations.data.evaluation
  }
}

const evaluationsStore = new EvaluationsStore();
export default evaluationsStore;

import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';

import TabBar from './components/TabBar';
import ViewUserData from './ViewUserData';
import ViewKBData from './ViewKBData';
import ViewPWData from './ViewPWData';

function ViewData(props) {


  const items = [
    {
      key: '1',
      label: `Student Contribution Data`,
      children: <ViewUserData />,
    },
    {
      key: '2',
      label: `Knowledge Battle Data`,
      children: <ViewKBData />,
    },
    {
      key: '3',
      label: `Proposal Writting Data`,
      children: <ViewPWData />,
    },
  ];


  return (
    <div>
      <TabBar />
      <Tabs defaultActiveKey="1" items={items} centered />;
    </div>
  )
}


export default ViewData;
import React, { Component } from 'react';
import { message, Modal } from 'antd';
import fileDownload from 'js-file-download'

import { getMyProposal, saveProposal, createProposal, submitProposal, downloadMyProposal } from './utils/request';
import styles from './css/WriteProposal.module.scss';
import TabBar from './components/TabBar';
import UnlockPage from './components/UnlockPage';
import Coin from './img/coin.png'
import Close from './img/close.png'
import IdeaBooster from './img/idea-booster.png'
import { countWords } from './utils/utils';
// import Unload from './components/Unload';
export default class WriteProposal extends Component {

  constructor(props) {
      super(props)
      this.state = {
        issue: JSON.parse(sessionStorage.getItem('issue')),
        contentWords: 0,
        title: '',
        content:'',
        openSubmitModal: false,
        openSaveModal: false,
        submitText: '',
        saveText: '',
        hasSavaed: false,
        onChangeContent: false,
      }
  }

  componentDidMount() {
    getMyProposal({issueId: this.state.issue.id}).then(res => {
      if (res.data) {
        this.setState({
          pageLock: res.data.lock,
          proposal: res.data.proposal
        })
      }
      if (res.data.proposal) {
        this.setState({
          title: res.data.proposal.title,
          titleWords: countWords(res.data.proposal.title),
          content: res.data.proposal.content,
          contentWords: countWords(res.data.proposal.content),
          submitProposal: res.data.proposal.isSubmit
        })
      }
    })
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  }

  handleBeforeUnload = (event) => {
    console.log(this.state.onChangeContent , !this.state.hasSavaed, 4343)
    if(this.state.onChangeContent && !this.state.hasSavaed) {
      event.preventDefault()
      event.returnValue = 'handleBeforeUnload'
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  }

  setTitle = (e) => {
    const titleWords = countWords(e.target.value)
    this.setState({
      titleWords: titleWords,
      title: e.target.value,
      onChangeContent: true,
      hasSavaed: false
    })
  }

  setContent = (e) => {
    const contentWords = countWords(e.target.value)
    this.setState({
      contentWords: contentWords,
      content: e.target.value,
      onChangeContent: true,
      hasSavaed: false
    })
  }

  showSubmitModal = () => {
    this.setState({openSubmitModal: true})
  }

  hideSubmitModal = () => {
    this.setState({openSubmitModal: false})
  }

  showHint = () => {
    if (this.state.title === '' || this.state.titleWords > 15) {
      message.open({
        type: 'warning',
        content: 'Please provide a title that has 15 words at most.',
      })
    } else if(this.state.content === '' || this.state.contentWords <= 300) {
      message.open({
        type: 'warning',
        content: 'Please make sure your essay has at least 300 words.',
      })
    }
  }

  handleOk = () => {
    submitProposal({
    issueId: this.state.issue.id,
    title: this.state.title,
    content: this.state.content,
  }).then(res => {
    if (res.data) {
      message.open({
        key: 'submit',
        type: 'success',
        className: styles.message,
        content: 
        <div className={styles.succcessHint}>
          <div>Your proposal has been received. You are now welcome to visit the Evaluation Committee and review the proposals submitted by your peers.</div>
          <img className={styles.closeIcon} src={Close} onClick={() => message.destroy('submit')}/>
        </div>,
        duration: 0,
      })
      this.setState({
        openSubmitModal: false,
        submitProposal: true,
        proposal: res.data,
        hasSavaed: true
      })
    }
    })
  }

  handleCancel = () => {
    this.setState({openSubmitModal: false})
  }

  saveContent = () => {
    this.setState({hasSavaed: true})
    if (this.state.proposal) {
      saveProposal({
        id: this.state.proposal.id,
        title: this.state.title,
        content: this.state.content
      }).then(res => {
        if (res.data) {
          message.open({
            type: 'success',
            content: 'Saved successfully',
          })
          this.setState({
            proposal: res.data
          })
        }
      })
    } else {
      createProposal({
        issueId: this.state.issue.id,
        title: this.state.title,
        content: this.state.content
      }).then(res => {
        if (res.data) {
          message.open({
            type: 'success',
            content: 'Saved successfully',
          })
          this.setState({
            proposal: res.data
          })
        }
      })
    }
  }

  download = () => {
    const downloadContent = this.state.title + '\n\n' + this.state.content
    fileDownload(downloadContent, 'My proposal.txt')
  }

  render () {

    const { issue, title, content, contentWords, submitProposal, proposal, openSubmitModal, onChangeContent, hasSavaed } = this.state
    const canSubmit = (this.state.title !== '' && this.state.titleWords <= 15) && (this.state.content !== '' && this.state.contentWords >= 300)
    const normalContent = (
      <div className={styles.content}>
          <div className={styles.divider} />

          <div className={styles.body}>
            <div className={styles.left}>
              <div className={styles.pre}>
                <pre style={{whiteSpace: 'pre-wrap', fontSize: '13px', lineHeight: '20px', color: '#898989', fontFamily: 'PingFangSC-Regular'}} dangerouslySetInnerHTML={{__html: issue.profile.proposalHint}} />
              </div>
              <div className={styles.leftFooter}>
                <div className={styles.ideaBooster} onClick={() => window.open('https://www.notion.so/How-to-Write-a-Good-Proposal-2a2010462acb4cbc8f7e1c389c0b701d?pvs=4')}>
                  <img src={IdeaBooster} className={styles.boosterImg} />
                  How to write a good proposal?
                </div>
              </div>
            </div>

            <div className={styles.right}>
              <div  className={styles.inputBox}>
                <div>
                  {
                    submitProposal ? <div className={styles.title}>{proposal.title}</div> :
                    <input placeholder='Title of proposal (no more than 15 words)' className={styles.title}  onChange={this.setTitle} value={title}/>
                  }
                </div>
                <div className={styles.inputDivider} />
                  <textarea placeholder='Type here (no less than 300 words)' className={styles.input} onChange={this.setContent} value={content} readOnly={submitProposal}/>
                <div className={styles.wordCounter}>
                  {contentWords} words
                </div>
              </div>

              <div className={styles.footer}>
                {
                  submitProposal ?
                  <div>
                    <div className={styles.download} onClick={this.download}>Download</div>
                  </div> :
                  <div className={styles.button}>
                    {
                      onChangeContent && !hasSavaed &&  <div className={styles.saveHint}>Save your draft before you leave this page.</div>
                    }
                    <div className={styles.save} onClick={this.saveContent}>Save</div>
                    <div className={styles.submit} onClick={canSubmit ? this.showSubmitModal : this.showHint}>
                      <span>Submit</span>
                      <img src={Coin} className={styles.submitImg}/>
                      <span className={styles.coinNums}>+100</span>
                      {/* <div className={styles.buttonDivider} /> */}
                    </div>
                  </div>
                }
              </div>
            </div>

          </div>

          {/* <Unload onChangeContent={onChangeContent} hasSavaed={hasSavaed} /> */}
          
          <Modal
            title={<div className={styles.modalTitle}>Attention</div>}
            open={openSubmitModal}
            centered
            width={"37.58vw"}
            closable={false}
            footer={null}
          >
            <div className={styles.modal}>
              <div className={styles.modalContent}>Are you sure you want to submit your proposal? You can only submit it once and won't be able to revise or delete it after your submission.</div>
              <div className={styles.btnGroup}>
                  <div className={styles.modalConfirm} onClick={this.handleOk} >Confirm</div>
                  <div className={styles.modalCancel} onClick={this.handleCancel}>Cancel</div>
                </div>
            </div>
          </Modal>

          {/* <Modal
            open={openSaveModal}
            centered
            width={"37.58vw"}
            closable={false}
            footer={null}
          >
            <div className={styles.modal}>
              <div className={styles.modalContent}>Save your proposal before you leave this page.</div>
              <div className={styles.btnGroup}>
                  <div className={styles.modalConfirm} onClick={this.saveContent} >Save</div>
                  <div className={styles.modalCancel} onClick={() => { this.setState({openSaveModal: false}) }}>Cancel</div>
                </div>
            </div>
          </Modal> */}
      </div>
    )

    return (
      <div className={styles.page}>
        <TabBar currentTab='write-proposal'/>
        {this.state.pageLock ? <UnlockPage page='proposal'/> : normalContent}
      </div>
    )
  }
}


import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Rate } from 'antd';
import userStore from './store/userStore';
import { getProposal } from './utils/request'

import styles from './css/Score.module.scss';
import IdeaBooster from './img/idea-booster.png'
import ScoreDetail from './components/ScoreDetail';
import Loading from './components/Loading';
import { generateRateValue } from './utils/utils';


function Score()  {

  const [proposal, setProposal] = useState()

  const proposalId = window.location.search.substring(1).split('=')[1]
  async function getProposalDetail() {
    const res = await getProposal({proposalId: proposalId})
    if (res.data) {
      setProposal(res.data)
    }
  }

  useEffect(() => {
    getProposalDetail()
  }, [])

  const userProfile = userStore.profile
  const [submitShowfooter, setSubmitShowfooter] = useState(false)
  const issue = JSON.parse(sessionStorage.getItem('issue'))

  if(!userProfile || !proposal) { return <Loading /> }  

  const hasScore = proposal && proposal.evaluations.length > 0
  const evaluatedByMe = proposal && proposal.evaluatedByMe
  const isMyProposal = proposal && proposal.creator.id === userProfile.id



// type分为三种：to be scored待打分、has scored已完成打分/自己的页面已有人打分、no scored自己的页面还未被打分
  // const type = (!isMyProposal && !hasScore) ? 'to be scored' :
  let type = ''
  if(userProfile.isAdmin) {
    type = 'has scored'
  } else if(!isMyProposal && !evaluatedByMe) {
    type = 'to be scored'
  } else if(evaluatedByMe || (isMyProposal && hasScore)) {
    type = 'has scored'
  } else {
    type = 'no scored'
  }
  const showFooter = (type && (type === 'no scored' || type === 'has scored')) || userProfile.isAdmin || submitShowfooter

  console.log(proposal.totalScore)



  const Footer = (
    <div className={styles.footer}>
      <div className={styles.footerLeft}></div>
      <div className={styles.footerRight}>
          <div className={styles.total}>
            <span className={styles.totalText}>Total:</span>
            <Rate
              allowHalf
              disabled
              value={generateRateValue(proposal.totalScore)}
              style={{color:'#F09B31'}}
            />
            <span className={styles.totalNum}>{proposal.totalScore}</span>
          </div>
          <div className={styles.scoreRule}>Total = (committee chair score + average of peer score) /2 </div>
      </div>
    </div>
  )

  return (
      <div className={styles.page}>
        <div className={styles.content}>
          <div className={styles.left}>
            <div className={styles.writer}>
              <img src={proposal.creator.avatar} className={styles.avatar}/>
              <span>{proposal.creator.username}</span>
            </div>
            <div className={styles.title}>{proposal.title}</div>
            <div className={styles.divider} />
            <div className={showFooter ? styles.textarea : styles.noFooterTextarea}>
              {proposal.content}
            </div>
          </div>
          <ScoreDetail
            type={type}
            proposal={proposal}
            evaluations={proposal.evaluations}
            submitEvaluate={() => {
              setSubmitShowfooter(true)
              getProposalDetail()
            }}
            />
        </div>
      {showFooter ? Footer : null}
      <div className={showFooter ? styles.booster : styles.noFooterBooster}>
        <div className={styles.ideaBooster} onClick={() => window.open('https://www.notion.so/How-to-Evaluate-a-Proposal-9601c3b457cc4c57aa171734bb9b99f0?pvs=4')}>
          <img src={IdeaBooster} className={styles.boosterImg} />
          How to evaluate a proposal?
        </div>
        <div className={styles.goBack} onClick={() => window.history.back()}><div className={styles.arrow}/><span>Back</span></div>
      </div>
    </div>
  )
}

export default observer(Score)
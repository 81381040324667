import React, { useState, useEffect } from 'react';
import { Table, Select, Switch } from 'antd';
import { getClass, getClassIssues, updateIssueAvailable, updateMaxMemberCount } from './utils/request';

import styles from '../src/css/CRM.module.scss'
import TabBar from './components/TabBar';

function CRM() {

  const [classInfo, setClassInfo] = useState()
  const [classId, setClassId] = useState()
  const [issueData, setIssueData ] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getClassInfo()
  }, [])

  const fetchIssueData = async () => {
    setLoading(true)
    const data = await getClassIssues({classId})
    setIssueData(data.data)
    setLoading(false)
  }

  useEffect(() => {
    if (classId) {
      fetchIssueData()
    }
  }, [classId])

  const getClassInfo = async () => {
    const classInfo = await getClass()
    setClassInfo(classInfo.data)
  }

  const updateAvailable = async (issueProfileId, available) => {
    const data = await updateIssueAvailable({classId, issueProfileId, available})
    if (data) {
      fetchIssueData()
    }
  }

  const updateMaxCount = async (countryId, count)=> {
    const data = await updateMaxMemberCount({countryId, count})
    if (data) {
      fetchIssueData()
    }
  }

  const classOptions = []
  classInfo && classInfo.map((item) => {
    classOptions.push({
      value: item.id,
      label: item.name
    })
  })

  const memberCnt = []
  for(let i=0; i<15; i++) {
    memberCnt.push({
      value: i+1,
      label: i+1
    })
  }

  const data = []
  // classInfo && classInfo.map((item, index) => {
  //   data.push({
  //     issue: 'Issue A',
  //     key: index,
  //     id: item.id,
  //     name: item.name,
  //     operation:
  //     <div className={styles.operation}>
  //      
  //     </div>
  //   })
  // })

  const columns = [
    {
      dataIndex: ['countryProfile', 'issueProfile', 'type'],
      key: 'type',
      width: 100,
      render: (text, record) => {
        console.log(record)
        return <div>
            <div>{text}</div>
            <div>{record.countryProfile.issueProfile.title}</div>
            <Switch checked={record.available} onChange={() => {updateAvailable(record.countryProfile.issueProfile.id, !record.available)}} />
          </div>
      },
      onCell: (_, index) => {
        if (index === 0 || index === 5 || index === 10) {
          return { rowSpan: 5 }
        } else {
          return { rowSpan: 0 }
        }
      },
    },
    {
      width: 100,
      dataIndex: ['countryProfile', 'name'],
      key: 'countryProfile',
    },
    {
      width: 400,
      dataIndex: 'issues',
      key: 'issues',
      fixed: 'left',
      render: (text, record) => {
        return (
          <div className={styles.issue}>
           {
            text.map(item => {
              return item.user.username
            }).join('、')
           }
          </div>
        )
      }
    },
    {
      dataIndex: 'maxMemberCnt',
      key: 'maxMemberCnt',
      width: 50,
      render: (text, record) => {
        return (
          <Select
           options={memberCnt}
           className={styles.selector}
           onChange={(value) => updateMaxCount(record.id, value)}
           value={text}
         />
        )
      }
    }
  ]
  

  return (
    <>
      <TabBar />
      <div className={styles.page}>
        <div className={styles.title}>Argument Arena  CRM</div>
        <div className={styles.selectGroup}>
          <div className={styles.selectTitle}>Select a class</div>
          <Select
            options={classOptions}
            className={styles.selector}
            onChange={(value) => setClassId(value)}
            value={classId}
          />
        </div>

        <Table
          className={styles.table}
          columns={columns}
          dataSource={issueData}
          // sticky
          bordered
          pagination={false}
          loading={loading}
          showHeader={false}
        />

      </div>
    </>
  )
}

export default CRM;
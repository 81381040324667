import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { message, Spin } from 'antd';
import { observer } from "mobx-react";

import { getUserIssues, getIssueCountries, joinCountry } from './utils/request';
import selectIssueStore from './store/selectIssueStore';
import userStore from './store/userStore';

import IssueCountry from './components/IssueCountry';
import Issue from './components/Issue'
import issues from './mock-data/getUserIssues.json'
import countriesMock from './mock-data/getIssueCountries.json'

import styles from './css/SelectIssue.module.css';
import IssueImg from './img/issue-img.png'
import StepDescIcon from './img/issue-desc-icon.png'
import TabBar from './components/TabBar';

function SelectIssue () {

    const navigate = useNavigate()
    const userProfile = userStore.profile
    const isAdmin = userProfile && userProfile.isAdmin

    const [issues, setIssues] = useState()
    const [selectIssue, changeIssue] = useState(0)
    const [profileId, setProfileId, ] = useState()
    const [countries, setCountries] = useState()
    const [selectCountry, changeCountry] = useState()
    const [loading, setLoading] = useState(false)
    const [countryLoading, setCountryLoading] = useState(false)

    document.getElementsByTagName('Body')[0].setAttribute('bgcolor', 'F4F4F4')
    selectIssueStore.selectIssue = selectIssue

    async function fetchUserIssues() {
        setLoading(true)
        const issues = await getUserIssues()
        setIssues(issues.data)
        setLoading(false)
        sessionStorage.setItem('issue', JSON.stringify(issues.data[0]))
        const profileId = issues.data[0].profile.id
        setProfileId(profileId)
    }

    useEffect(() => {
        fetchUserIssues()
    }, [])

    async function fetchIssueCountries() {
        if (issues) {
            setCountryLoading(true)
            const countries = await getIssueCountries({issueProfileId: profileId})
            // const countries = countriesMock // mock data
            setCountries(countries.data)
            sessionStorage.setItem('countries', JSON.stringify(countries.data))
            const country = countries.data.find(item => item.isJoined)
            const selectCountry = countries.data.findIndex(item => item.isJoined)
            if (country) {
                changeCountry(selectCountry)
                sessionStorage.setItem('country', JSON.stringify(country))
            } 
            setCountryLoading(false)
        }   
    }

    async function submitJoinCountry() {
        await joinCountry({
            issueProfileId: profileId,
            country: countries[selectCountry].country
        })
        await fetchIssueCountries()
    }

    useEffect(() => {
        fetchIssueCountries()
    }, [profileId])
    
    const [messageApi, contextHolder] = message.useMessage()

    const showHint = () => {
        if(!countries[selectCountry].available) {
            messageApi.open({
                type: 'warning',
                content: 'This issue is not available now',
            })
        } else if(!(selectCountry.toString())) {
            messageApi.open({
                type: 'warning',
                content: 'Please select a country',
            })
        }
    }

    const goNext = async () => {
        if(isAdmin) {
            sessionStorage.setItem('country', JSON.stringify(countries[0]))
            navigate('/create-idea?countryIndex=0')
        }else if(!(countries.find(item => item.isJoined))) {
            await submitJoinCountry()
            navigate('/create-idea?countryIndex=0')
        } else {
            navigate('/create-idea?countryIndex=0')
        }
    }

    const disabled = countries && countries.find(item => item.isJoined)
    const canStart = (selectCountry !== undefined && countries[selectCountry].available) || isAdmin

    return (
        <div>
            <TabBar currentTab='select-issue' hideMenu selectIssue={selectIssue}/>
            <div className={styles.body}>
                <div className={styles.content}>
                    <div className={styles.left}>
                        <img src={IssueImg} className={styles.leftImg} />
                        <div className={styles.leftContent}>
                            <div className={styles.leftDesc}>
                            Hey there!<br /><br />We’ve got three crucial issues that affect people all over the world. 
                            As representatives from different countries,
                            you’ll be discussing these issues in three steps:
                            </div>
                            <div className={styles.stepContent}>
                                <div className={styles.step}>
                                    <img src={StepDescIcon} className={styles.stepDescIcon} />
                                    <div className={styles.stepDescText}>
                                        <div className={styles.stepTitle}>
                                            Step 1: Idea Creation
                                        </div>
                                        <div className={styles.stepDesc}>You’ll brainstorm ideas on how to solve the issue at hand.</div>
                                    </div>
                                </div>
                                <div className={styles.step}>
                                    <img src={StepDescIcon} className={styles.stepDescIcon} />
                                    <div className={styles.stepDescText}>
                                        <div className={styles.stepTitle}>
                                            Step 2: Proposal Writing
                                        </div>
                                        <div className={styles.stepDesc}>Based on the ideas generated in step 1, you will write proposals outlining your solutions.</div>
                                    </div>
                                </div>
                                <div className={styles.step}>
                                    <img src={StepDescIcon} className={styles.stepDescIcon} />
                                    <div className={styles.stepDescText}>
                                        <div className={styles.stepTitle}>
                                            Step 3: Evaluation Committee
                                        </div>
                                        <div className={styles.stepDesc}>You’ll review each other's proposals. The Committee Chair will evaluate your proposals as well.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <Spin spinning={loading}>
                            <div className={styles.issue}>
                                <div className={styles.issueTitle}>Select an issue you want to discuss</div>
                                <div className={styles.issueContent}>
                                    {
                                        issues && issues.map((issue, index) => {
                                            return(
                                            <Issue
                                                issue={issue}
                                                isSelected={selectIssue === index}
                                                changeIssue={() => {
                                                    changeIssue(index)
                                                    setProfileId(issue.profile.id)
                                                    sessionStorage.setItem('issue', JSON.stringify(issue))
                                                    !issue.joinCountry && changeCountry()
                                                    selectIssueStore.selectIssue = index
                                                }}
                                                index={index}
                                                key={index}
                                            />
                                        )}
                                    )}
                                </div>
                            </div>
                        </Spin>
                        <Spin spinning={countryLoading}>
                            <div className={styles.country}>
                                <div className={styles.countryTitle}>
                                    Select a country you want to represent
                                    <span className={styles.hint}> (you will not be allowed to change this)</span>
                                </div>
                                <div className={styles.countryContent}>
                                    {
                                        countries && countries.map((country, index) => {
                                            return (
                                                <IssueCountry
                                                    country={country}
                                                    changeCountry={() => {
                                                        changeCountry(index)
                                                        sessionStorage.setItem('country', JSON.stringify(country))
                                                    }}
                                                    disabled={disabled}
                                                    isSelected={selectCountry === index}
                                                    key={index}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Spin>
                    </div>
                </div>
                <div className={canStart ? styles.button : styles.disabled} onClick={canStart ? goNext : showHint}>Start</div>
                {contextHolder}
            </div>
        </div>
    )
}

export default observer(SelectIssue);

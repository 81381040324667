import { Radio, Popover } from 'antd';
import React from 'react';
import styles from '../css/IssueCountry.module.css'

class IssueCountry extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {

        const { country, disabled, isSelected, changeCountry } = this.props
        const isFullMember = country.members.length >= country.maxMemberCnt
        const content = country.members.map(item => {
            return <p>{item.user.username}</p>
        })

        if (content.length === 0) {
            content.push(<p>N/A</p>)
        }

        return (
            <Popover  content={<div className={styles.contentWidth}>{content}</div>}>
                <div className={styles.body}>
                    <img src={country.flag} className={styles.img} />
                    <div className={styles.name}>{country.country}</div>
                    <div className={styles.selectNum}>({country.members.length+'/'+country.maxMemberCnt})</div>
                    <Radio
                        style={{marginLeft: 8, marginTop: 5}}
                        checked={disabled ? country.isJoined : isSelected}
                        onChange={changeCountry}
                        disabled={disabled || isFullMember}
                    />
                </div>
            </Popover>
            )
    }
  }
  
  export default IssueCountry;
export function generateRateValue(value) {
    const rate = Number(value)
    if(Number.isInteger(rate)) return rate
    return Math.floor(rate*2) / 2;
}


export function countWords(s){
    s = s.replace(/(^\s*)|(\s*$)/gi,"");//exclude  start and end white-space
    s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
    s = s.replace(/\n /,"\n"); // exclude newline with a start spacing
    return s.split(' ').filter(function(str){return str!="";}).length;
    //return s.split(' ').filter(String).length; - this can also be used
}

export function countAvgScore(evaluation) {
    const total = (evaluation.contentScore + evaluation.organizationScore + evaluation.languageScore) / 3
    return total
}

export function genScore(number) {
    return (Math.round(number * 10) / 10).toFixed(1)
}

export function genPopContent(evaluations) {
    return <div>
        {
            evaluations.map((evaluation, index) => {
                return (
                    <div>
                        <p style={{fontWeight: 'bold'}}>{evaluation.creator && evaluation.creator.username}</p>
                        <p>Content ({evaluation.contentScore}); Organization ({evaluation.organizationScore}); Language ({evaluation.languageScore})</p>
                        <p>Comment: {evaluation.content}</p>
                    </div>
                )
            })
        }
    </div>
}
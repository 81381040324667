import { Popover } from 'antd';
import React from 'react';
import styles from '../css/IdeaParticipants.module.css'

export default class IdeaParticipants extends React.Component {

    constructor(props) {
        super(props)
      }

    render() {

        const { member } = this.props
        return (
            <Popover
                content={member.user.username}
                trigger="hover"
                overlayClassName={styles.pop}
                >
                <img src={member.user.avatar} className={styles.avatar}/>
            </Popover>
            )
    }
}
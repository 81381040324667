import React from 'react';
import styles from '../css/IdeaCountry.module.css'

class IdeaCountry extends React.Component {

  constructor(props) {
    super(props)
}

    render() {

      const { country, isSelected } = this.props
      return (
        <div className={styles.country} onClick={this.props.onClick}>
          <div className={isSelected ? styles.selected : styles.normal}>
            <img src={country.flag} className={styles.countryIcon}/>
          </div>
          <div>{country.country}</div>
        </div>
      )
    }
  }
  
  export default IdeaCountry;
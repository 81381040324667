import React from 'react';
import { Radio, Space, RadioChangeEvent } from 'antd';
import styles from '../css/QuestionItem.module.css';
import Wrong from '../img/wrong.png'
import Correct from '../img/correct.png'

export default class QuestionItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: null,
    }
  }

  onChange = (e) => {
    const { setSelectAnswer } = this.props
    const { questionIndex } = this.props
    setSelectAnswer(questionIndex, e.target.value)
  }

  convertIndexToChar = (index) => {
    return (String.fromCharCode(65 + index)).toLowerCase() + ') '
  }

  render() {
    const {question, questionIndex, selectAnswer} = this.props
    const options = question.options && JSON.parse(question.options)

    return(
      <div className={questionIndex%2 === 0 ? styles.itemWhite : styles.itemGrey}>
        <div className={styles.question}>{question.question}</div>
        <Radio.Group onChange={this.onChange} value={selectAnswer}>
          <Space direction="vertical">
            {
              options &&
              options.map((option, i) => 
              <Radio value={i}>{this.convertIndexToChar(i)}{option}</Radio>
              )
            }
          </Space>
        </Radio.Group>
      </div>
    )
  }
  
}

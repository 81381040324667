import React, { Component } from 'react';
import { observer } from "mobx-react";

import styles from './css/EvaluationCommittee.module.css';
import TabBar from './components/TabBar';
import UnlockPage from './components/UnlockPage';
import EvaluationList from './components/EvaluationList'
import { getEvaluationList } from './utils/request';
import evaluationsStore from './store/evaluationsStore';
import { Spin } from 'antd';

class EvaluationCommittee extends Component {

  constructor(props) {
      super(props)
      this.state = {
        issue: JSON.parse(sessionStorage.getItem('issue')),
        loading: false
      }
  }

  componentDidMount () {
    document.getElementsByTagName('Body')[0].setAttribute('bgcolor', 'F7F7F7')
    this.fetchData()
    // evaluationsStore.refreshEvaluationList({issueId: this.state.issue.id})
  }
    
  fetchData = () => {
    this.setState({
      loading: true
    })
    getEvaluationList({issueId: this.state.issue.id}).then(res => {
      this.setState({
        pageLock: res.data.lock,
        evaluationList: res.data.evaluation,
        loading: false
      })
    })
  }

  render () {
      const { issue, evaluationList, pageLock, loading } = this.state
      // const evaluationList = evaluationsStore.evaluations
      const normalContent = (
        <div className={styles.content}>

          <div className={styles.title}>{issue.profile.subTitle}</div>
          { evaluationList && evaluationList.map((e, i) => {
              return (<EvaluationList list={e} countryIndex={i} key={i}/>)
            }) 
          }
          
        </div>
      )

      return (
        <div className={styles.page}>
          <TabBar currentTab='evaluation-committee'/>
          <Spin spinning={loading}>
            {pageLock ? <UnlockPage page='evaluation'/> : normalContent }
          </Spin>
        </div>
      )
    }
}

export default observer(EvaluationCommittee)

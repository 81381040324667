import React from 'react';
import { Rate, message } from 'antd';
import { observer } from 'mobx-react';

import { submitEvaluation } from '../utils/request'
import styles from '../css/ScoreDetail.module.css';
import ScoreImg from '../img/score-img.png'
import CommentDetail from './CommentDetail'
import userStore from '../store/userStore';
import Loading from './Loading';
import { countWords } from '../utils/utils';

class ScoreDetail extends React.Component {
  constructor(props) {
    super(props)
    // data: type 
    // type分为三种：to be scored待打分、has scored已完成打分/自己的页面已有人打分、no scored自己的页面还未被打分
    this.state = {
      evaluations: this.props.evaluations,
      type: this.props.type,
      contentScore: 0,
      organizationScore: 0,
      languageScore: 0,
      countWords: 0,
      comment: '',
    }
  }

  writeComment = (e) => {
    const count = countWords(e.target.value)
    this.setState({
      countWords: count,
      comment: e.target.value
    })
  }

  showHint = () => {
    const { contentScore, organizationScore, languageScore, countWords } = this.state
    if(contentScore === 0 || organizationScore === 0 || languageScore === 0) {
      message.open({
        type: 'warning',
        content: 'Please provide an evaluation of the proposal\'s content, organization, and language.',
      })
    } else if(countWords < 20 ){
      message.open({
        type: 'warning',
        content: 'Please input no less than 20 words.',
      })
    }
  }

  submit = () => {
    const issue = JSON.parse(sessionStorage.getItem('issue'))
    submitEvaluation({
      issueId: issue.id,
      proposalId: this.props.proposal.id,
      contentScore: this.state.contentScore,
      organizationScore: this.state.organizationScore,
      languageScore: this.state.languageScore,
      content: this.state.comment
    }).then(res => {
      if (res.data) {
        this.setState({
          evaluations: res.data.evaluations,
          type: 'has scored'
        })
        this.props.submitEvaluate()
      }
    })
  }

  render() {

    const userProfile = userStore.profile
    const { evaluations, type } = this.state
    const canSubmit = this.state.contentScore > 0 && this.state.organizationScore > 0 && this.state.languageScore > 0 && this.state.countWords >= 20
    const isAdmin = userProfile.isAdmin
    const isEvaluatedByAdmin = evaluations.find(item => item.byAdmin)

    if(!userProfile) { return <Loading /> }  

    const ScoreBox = (
      <div>
        <div className={styles.evaluateTitle}>Please evaluate this proposal</div>
        <div className={styles.evaluateItem}>
          <div className={styles.evaluateDesc}>Content</div>
          <Rate
            style={{color:'#F09B31'}}
            value={this.state.contentScore}
            onChange={(value) => this.setState({contentScore: value})}
          />
        </div>
        <div className={styles.evaluateItem}>
          <div className={styles.evaluateDesc}>Organization</div>
          <Rate
            style={{color:'#F09B31'}}
            value={this.state.organizationScore}
            onChange={(value) => this.setState({organizationScore: value})}
          />
        </div>
        <div className={styles.evaluateItem}>
          <div className={styles.evaluateDesc}>Language</div>
          <Rate
            style={{color:'#F09B31'}}
            value={this.state.languageScore}
            onChange={(value) => this.setState({languageScore: value})}
          />
        </div>

        <div className={styles.commentBox}>
          <div className={styles.scorer}>
            <img src={userProfile.avatar} className={styles.scorerAvatar} />
            <span>{userProfile.username}</span>
          </div>
          <div className={styles.commentDivider} />
          <textarea className={styles.input} placeholder='Add comments (no less than 20 words)' onChange={this.writeComment}/>
          <div className={styles.wordCounter}>
            {this.state.countWords} words
          </div>
        </div>
      </div>
    )

    const ToBeScored = (
      <div className={styles.scorePage}>
        {ScoreBox}
        <div className={styles.scoreFooter}>
          <div className={canSubmit ? styles.button : styles.buttonDisable} onClick={canSubmit ? this.submit : this.showHint}>Submit</div>
        </div>
        <div className={styles.toBeScoreImg}>
          <img src={ScoreImg} className={styles.scoreImg}/>
        </div>
      </div>
    )

    const AdminScoreBox = (
      <div className={styles.adminScoreBox}>
        {ScoreBox}
        <div className={styles.adminScoreFooter}>
          <div className={canSubmit ? styles.button : styles.buttonDisable} onClick={canSubmit ? this.submit : this.showHint}>Submit</div>
        </div>
      </div>
    )

    const FinishScored = (
      <div className={styles.finishPage}>
        <div className={styles.scroll}>
          <div className={styles.finishPageContent}>
            {isAdmin && !isEvaluatedByAdmin && AdminScoreBox}
            {evaluations.length > 0 && evaluations.map((e, i) => <CommentDetail evaluation={e} key={i}/>)}
          </div>
        </div>
      </div>
    )

    const NoScored = (
      <div className={styles.NoScorePage}>
        <div className={styles.noScoreNoteBlock}><div className={styles.noScoreNote}>Your proposal is still awaiting evaluation.</div></div>
        <div className={styles.noScoreImgBlock}><img src={ScoreImg} className={styles.noScoreImg}/></div>
        <div className={styles.buttomFooter}></div>
      </div>
    )
    
      console.log('type', type)

    if (type && type === 'to be scored') {
      return ToBeScored
    } else if (type && type  === 'has scored') {
      return FinishScored
    } else {
      return NoScored
    }

  }
  
}

export default observer(ScoreDetail)
import React from 'react';
import styles from '../css/UnlockPage.module.css';
import MyAccount from '../img/my-account.png'

class UnlockPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className={styles.page}>
          <div className={styles.content}>
            <img src={MyAccount} className={styles.unlockImg}/>
            <div className={styles.hint}>
              {this.props.page === 'proposal' && 'Create at least one idea to unlock this page'}
              {this.props.page === 'evaluation' && 'Submit your proposal to unlock this page' }
            </div>
          </div>
        </div>
    )
  }
  
}

export default UnlockPage;

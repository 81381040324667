import React from 'react';
import styles from '../css/EvaluationList.module.css';
import EvaluationListItem from './EvaluationListItem'

export default class EvaluationList extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { list, countryIndex } = this.props
    return (
      <div className={styles.page}>
        <div className={styles.country}>
          <img src={list.countryProfile.flag} className={styles.countryImg} />
          <div className={styles.countryName}>{list.countryProfile.name}</div>
        </div>

        <div className={styles.list}>
          {
            list && list.proposals && list.proposals.length > 0 && list.proposals.map((e, i) => <EvaluationListItem item={e} countryIndex={countryIndex} index={i} key={i} />)
          }
          {
            list && list.proposals && list.proposals.length === 0 && <div className={styles.noProposal}>Representatives from this country have not submitted any proposals.</div>
          }
          
        </div>
      </div>
    )
  }
  
}

import { Rate } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { observer } from "mobx-react";

import styles from '../css/EvaluationListItem.module.css';
import Coin from '../img/coin.png'
import { generateRateValue } from '../utils/utils';

function EvaluationListItem(props) {

  const navigate = useNavigate()

  const goEvaluate = () => {
    navigate(`/score?proposalId=${props.item.id}`)
  }

  const { item, countryIndex, index } = props
  const isMyProposal = countryIndex === 0 && index === 0
  const submitAt = dayjs(item.submitAt).format('YYYY/MM/DD HH:mm:ss')
  
  return (
    <div className={styles.item}>
      <div className={styles.desc}>
        <div className={styles.nameAndDate}>({isMyProposal ? 'My Proposal' : item.creator.username}; {submitAt})</div>
        <div className={styles.title}>{item.title}</div>
      </div>
        {
        (item.evaluatedByMe || isMyProposal) ? 
        <div className={styles.rate}>
          <Rate
            allowHalf
            disabled
            value={generateRateValue(item.totalScore)}
            style={{color:'#F09B31'}}
          />
          <div className={styles.rateNum}>{item.totalScore}</div>
          <div className={styles.arrow}  onClick={goEvaluate}/>
        </div> :
        <div className={styles.evaluate} onClick={goEvaluate}>
          <span>Evaluate</span>
          {/* <div className={styles.divider} /> */}
          <img src={Coin} className={styles.coin}/>
          <span className={styles.coinNums}>{countryIndex === 0 ? '+20' : '+15'}</span>
        </div>
        }
    </div>
  )
}
export default observer(EvaluationListItem)
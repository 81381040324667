import React, { useState, useEffect } from 'react';
import { Table, Pagination, Popover } from 'antd';
import { getProposalWritting, getClass } from './utils/request';
import dayjs from 'dayjs'
import styles from '../src/css/ViewData.module.scss'
import { countAvgScore, countWords, genScore, genPopContent } from './utils/utils';

function ViewPWData(props) {

  const [classInfo, setClassInfo] = useState()

  const [userPage, setUserPage] = useState(1)
  const [userPageLimit, setUserPageLimit] = useState(10)
  const [proposalwrittingPage, setProposalWrittingPage] = useState(1)
  const [proposalwrittingPageLimit, setProposalWrittingPageLimit] = useState(10)
  const [proposalwrittingData, setProposalWrittingData] = useState()
  const [userData, setUserData] = useState()
  const [classFilter, setClassFilter] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getClassInfo()
  }, [])

  const getClassInfo = async () => {
    const classInfo = await getClass()
    setClassInfo(classInfo.data)
  }


  useEffect(() => {
    getProposalWrittingData()
  }, [proposalwrittingPage, classFilter, proposalwrittingPageLimit])


  const getProposalWrittingData = async () => {
    setLoading(true)
    const result = await getProposalWritting({
      page: proposalwrittingPage,
      pageLimit: proposalwrittingPageLimit,
      ...classFilter
    })
    if (result) {
      setProposalWrittingData(result.data)
    }
    setLoading(false)
  }


  const proposalwrittingColumns = [
    {
      title: 'Name',
      dataIndex: 'username',
      key: 'username',
      width: 200,
    },
    {
      title: 'Class',
      dataIndex: ['class', 'name'],
      key: 'classId',
      filters: classInfo && classInfo.map(item => ({ text: item.name, value: item.id })),
      filterMultiple: false,
    },
    {
      title: 'issue',
      dataIndex: 'issueType',
      key: 'issueType',
    },
    {
      title: 'Proposal submitted',
      dataIndex: ['proposal', 'isSubmit'],
      key: 'submitted',
      render: (text, record) => {
        if (typeof text == "boolean") {
          console.log(record)
          return text ? <Popover content={<div><p style={{fontWeight: 'bold'}}>{record.proposal.title}</p><p>{record.proposal.content}</p></div>}>Yes</Popover> : 'No'
        }
        return ''
      }
    },
    {
      title: 'Submission time',
      dataIndex: ['proposal', 'submitAt'],
      key: 'submitAt',
      render: (text) => {
        if (text === undefined) return ''
        return text ? dayjs(text).format('YYYY/MM/DD HH:mm:ss') : "N/A"
      }
    },
    {
      title: 'No. of words',
      dataIndex: ['proposal', 'words'],
      key: 'words',
      render: (text) => {
        if (text === undefined) return ''
        return text
      }
    },
    {
      title: 'No. of peer evaluation',
      dataIndex: ['proposal', 'evaluations'],
      key: 'evaluation count',
      render: (evaluations) => {
        if (!evaluations) return ''
        return evaluations.filter(item => !item.byAdmin).length
      }
    },
    {
      title: 'Peer evaluation',
      dataIndex: ['proposal', 'evaluations'],
      key: 'Peer evaluation',
      render: (evaluations) => {
        if (!evaluations) return ''
        const studentEvaluation = evaluations.filter(item => !item.byAdmin)
        if (studentEvaluation[0]) {
          const studentScoreList = studentEvaluation.map(item => countAvgScore(item))
          const total = studentScoreList.reduce((a, b) => a + b, 0)
          const popContent = genPopContent(studentEvaluation)

          return <Popover content={popContent} title="Peer evaluations">
           {genScore(total / studentEvaluation.length)}
          </Popover>
        } else {
          return 'N/A'
        }
      }
    },
    {
      title: 'Teacher evaluation',
      dataIndex: ['proposal', 'evaluations'],
      key: 'Teacher evaluation',
      render: (evaluations) => {
        if (!evaluations) return ''
        const teacherEvaluation = evaluations.filter(item => item.byAdmin)
        if (teacherEvaluation[0]) {
          const popContent = genPopContent(teacherEvaluation)
          return <Popover content={popContent} title="Teacher evaluation">
            {genScore(countAvgScore(teacherEvaluation[0]))}
          </Popover>
        } else {
          return 'N/A'
        }
      }
    },
    {
      title: 'Total score',
      dataIndex:  ['proposal', 'totalScore'],
      key: 'totalScore',
      render: (text) => {
        if (text === undefined) return ''
        return text
      }
    },
  ]

  const realData = []
  proposalwrittingData && proposalwrittingData.data && proposalwrittingData.data.forEach((user) => {
      // if (user.proposals.length === 0) {
      //   realData.push({
      //     ...user,
      //     children: 
      //   })
      // }

      const children = user.proposals.map(proposal => {
        return {
          // ...user,
          proposal: {
            ...proposal,
            words: countWords(proposal.content)
          },
          username: '',
          class: '',
          issueType: proposal.issue && proposal.issue.profile.type,
          key: proposal.id.toString(),
        }
      })

      realData.push({
        ...user,
        issueType: '',
        key: 'user' + user.id,
        children,
      })
  })

  return (
    <div>
      <div className={styles.page}>
        <div className={styles.tableTwo}>
          <div>Proposal Writting Data</div>
          <Table
            className={styles.table}
            columns={proposalwrittingColumns}
            dataSource={realData}
            sticky
            bordered
            pagination={false}
            loading={loading}
            expandable={{ expandedRowKeys: realData.map(item => 'user' + item.id), expandIcon: null }}
            onChange={(pagination, filters) => setClassFilter(filters)}

          />
          <div className={styles.pagination}>
            <Pagination
              current={proposalwrittingPage}
              showTotal={(total) => `Total ${total} items`}
              total={proposalwrittingData && proposalwrittingData.count}
              onChange={(page) => setProposalWrittingPage(page)}
              pageSizeOptions={[10, 20, 50]}
              showSizeChanger
              onShowSizeChange={(current, pageSize) => {
                setProposalWrittingPageLimit(pageSize)
              }}
            />
        </div>
        </div>
      </div>
    </div>
  )

  
}


export default ViewPWData;
import React from 'react';
import { Radio, Space, RadioChangeEvent } from 'antd';
import styles from '../css/QuestionItem.module.css';
import Wrong from '../img/wrong.png'
import Correct from '../img/correct.png'

export default class AnswerItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: null,
    }
  }

  onChange = (e: RadioChangeEvent) => {
      // this.setState({
      //   value: e.target.value,
      // })
  }

  convertIndexToChar = (index) => {
    return (String.fromCharCode(65 + index)).toLowerCase() + ') '
  }

  render() {
    const {question, questionIndex, answer, correctAnswer, explanation, isCorrect} = this.props
    
    const options = question.options && JSON.parse(question.options)
    console.log(answer)

    return(
    <div className={questionIndex%2 === 0 ? styles.finishedItemWhite : styles.finishedItemGrey}>
      <div className={styles.finishedLeft}>
        <div className={styles.title}>
          <img src={isCorrect ? Correct : Wrong} className={styles.icon}/>
          <div className={styles.question}>{questionIndex + 1}. {question.question}</div>
        </div>
        <Radio.Group onChange={this.onChange} value={answer}>
          <Space direction="vertical">
            {
              options &&
              options.map((option, i) => 
                <Radio value={i} className={i === correctAnswer ? styles.correctAnswer: ""}>{this.convertIndexToChar(i)}{option}</Radio>
              )
            }
          </Space>
        </Radio.Group>
      </div>

      <div className={styles.finishedRight}>
        <div className={styles.expTitle}>Explanation</div>
        <div className={styles.explanation}>{explanation}</div>
      </div>
    </div>
  )}
  
}

import React from 'react'

import styles from '../css/Comment.module.css'
import CommentIcon from '../img/comment.png'

export default class Comment extends React.Component {

  constructor(props) {
    super(props)
  }


  render() {
    const { comments } = this.props

    return (
      <div className={styles.group}>
        <img src={CommentIcon} className={styles.icon} />
        {comments}
      </div>
    )
  }
  
}
